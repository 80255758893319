<template>
  <div class="addLibDrawer">
    <!-- 添加资源抽屉 -->
    <el-drawer :visible.sync="drawer" :size="1200" @close="drawerClose()">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">添加题库资源</div>
        <div class="drawer-title-btns">
          <el-button size="small" @click="drawerClose()">取消</el-button>
          <el-button size="small" type="primary" @click="submit()">确定</el-button>
        </div>
      </div>
      <div style="padding: 20px;">
        <el-form ref="addForm" :model="addEditForm" label-width="80px" size="small" :rules="formRule">
          <!-- <el-form-item label="题库类型">
            <el-radio-group v-model="questionType" @change="questionTypeChange">
              <el-radio :label="item.id" v-for="item in questionTypeList" :key="item.id">{{ item.name }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="题库" prop="category_id">
            <el-cascader class="cascader" v-model="addEditForm.categoryCasList" :show-all-levels="false" filterable
              :options="categoryTreeList" :props="optionProps" @change="handleChange" placeholder="请选择题库分类">
            </el-cascader>
          </el-form-item> -->
          <el-form-item label="资源类型" prop="type">
            <el-radio-group v-model="addEditForm.type" @change="libTypeChange">
              <el-radio :label="item.id" v-for="item in drawerLibTypeList" :key="item.id">{{ item.name }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="上传类型">
            <el-radio-group v-model="addEditForm.uploadType" @change="uploadTypeChange">
              <el-radio :label="item.id" v-for="item in uploadTypeList" :key="item.id">{{ item.name }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="文件" prop="source_datas" key="local">
            <div style="display: flex;align-items: center;">
              <UploadView :idn="'upload1'" style="height: 32px;" :btnTitle="btnTitle" @success="handleAvatarSuccess"
                @error="handleError" :accept="accept" v-loading="uploadLoading" @beforeUpload="beforeUpload" @progress="progress" />
              <div v-if="addEditForm.uploadType === '2'" style="margin-left: 10px;" key="downloadTemplate">
                <el-button type="text" size="small" @click="downloadTemplate()">下载资源模板</el-button>
              </div>
              <div v-else style="margin-left: 10px; font-size: 12px; color: gray;">支持多文件上传</div>
              <div style="margin-left: 40px; font-size: 12px; color: gray;">支持上传类型：{{ accept }}</div>
            </div>
            <!-- <div class="uploadTips" v-if="addEditForm.uploadType === '2' && !addEditForm.categoryCasList.length">
              <el-alert size="small" :closable="false" title="请先选择题库类型" type="warning">
              </el-alert>
            </div> -->
          </el-form-item>
          <el-form-item v-if="addEditForm.uploadType === '2'" key="externalLinks">
            <!-- <div class="external-links" v-for="(item, index) in externalLinksList" :key="index">
              <el-input v-model="externalLinksList[index].name" type="textarea" placeholder="请输入外部资源名称"></el-input>
              <el-input size="small" class="external-input" v-model="externalLinksList[index].path" type="textarea"
                placeholder="请输入外部链接地址"></el-input>
              <el-button v-if="externalLinksList.length > 1" size="small" class="external-add"
                @click="delExternal(index)">-</el-button>
              <el-button v-if="externalLinksList.length === index + 1" size="small" class="external-add"
                @click="addExternalLinks()">+</el-button>
            </div> -->

            <el-table
              :data="externalLinksList"
              border
              style="width: 100%">
              <el-table-column
                prop="name"
                label="名称">
                  <template v-slot="{$index}">
                    <el-input v-model="externalLinksList[$index].name" type="textarea" placeholder="请输入外部资源名称"></el-input>
                  </template>
              </el-table-column>
              <el-table-column
                prop="path"
                label="链接">
                  <template v-slot="{$index}">
                    <el-input size="small" class="external-input" v-model="externalLinksList[$index].path" type="textarea"
                        placeholder="请输入外部链接地址"></el-input>
                  </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="操作"
                width="120">
                <template v-slot="{$index}">
                  <el-button v-if="externalLinksList.length > 1" size="small" class="external-add"
                    @click="delExternal($index)">-</el-button>
                  <el-button v-if="externalLinksList.length === $index + 1" size="small" class="external-add"
                    @click="addExternalLinks()">+</el-button>
                </template>
              </el-table-column>
            </el-table>

          </el-form-item>
        </el-form>
        <!-- 上传的文件列表区域 -->
        <div class="file-list" v-show="addEditForm.uploadType === '1'">
          <el-table :data="uploadList" border style="width: 100%" :cell-class-name="tableCellClassName">
            <el-table-column prop="uploadfile_id" width="80" label="序号">
            </el-table-column>
            <el-table-column prop="name" label="资源名称">
              <template slot-scope="scope">
                <template v-if="scope.$index === cellRowIndex && scope.column.index === cellColumnIndex">
                  <el-input v-model="uploadList[scope.$index].name" @blur="nameInputBlur(scope)" size="small"
                    :ref="'editName' + scope.$index"></el-input>
                </template>
                <template v-else>
                  <span>{{ scope.row.name }}<i class="el-icon-edit icon-edit" @click="editName(scope)"></i></span>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="进度" prop="percentage" width="100"></el-table-column>
            <el-table-column prop="link" label="链接" v-if="addEditForm.type === '3'" key="link">
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template v-slot="{ row }">
                <el-button size="mini" type="primary" @click="copyLink(row)">复制链接</el-button>
                <el-button size="mini" type="primary" @click="preview(row)">预览</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
    <!-- 预览资源抽屉 -->
    <el-drawer :visible.sync="previewDrawer" size="80%">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">预览</div>
      </div>
      <div style="padding: 20px; height: 100%;">
        <video v-if="previewData.type === '1' && !webofficeShow" :src="previewData.previewSrc" controls width="100%" height="100%"
          style="background-color: black;" controlslist="nodownload"></video>
        <embed v-else-if="previewData.type !== '1' && !webofficeShow" :src="previewData.previewSrc" id="embed2" width="100%" height="100%" type="application/pdf" />
        <div ref="webOfficeDOM" id="webOffice" style="height: 100%;" v-else></div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import util from "@/utils/tools.js";
import CONSTANT from "@/utils/constant.js";
import UploadView from "@/components/UploadView.vue";
import WebOfficeSDK from "@/assets/js/webOffice.js";
export default {
  name: "AddLibDrawer",
  props: {
    // 抽屉可见性
    drawerShow: {
      type: Boolean,
      default: false
    },
    // 抽屉配置
    drawerData: {
      type: Object,
      default: () => {
        return {
          type: "0", //题库类型
          category_id: "-1", // 一级分类
          category_second_id: "-1", // 二级分类
          libType: "0" // 资源类型
        }
      }
    }
  },
  components: {
    UploadView
  },
  created() {
    // 获取类型
    this.getCategoryData();
    // 获取分类数据
    // this.getCategoryTreeData();
    // 获取类型数据
    this.getLibType();
  },
  data() {
    // 上传文件表单规则
    const validateSource = (rule, value, callback) => {
      if (!this.uploadList.length && this.addEditForm.uploadType !== "2") {
        return callback("请上传文件");
      }
      if (this.addEditForm.uploadType === "2") {
        const flag = this.externalLinksList.some(item => {
          return item.name === "" || item.name === "";
        });
        if (flag) {
          if (this.externalLinksList.length === 1) {
            return callback("请上传excel或者手动填写");
          } else {
            return callback("请检查是否有未填写项");
          }
        }
      }
      callback();
    }
    return {
      Cookies,
      // 抽屉可见性
      drawer: false,
      // 预览文件抽屉可见性
      previewDrawer: false,
      // 预览文件抽屉的数据
      previewData: {
        type: "",
        previewSrc: ""
      },
      // 添加|编辑的表单
      addEditForm: {
        category_id: "",
        categoryCasList: [],
        type: "",
        source_datas: [],
        uploadType: "1"
      },
      // 是否正在上传
      uploadLoading: false,
      acceptObj: {
        "1": "mp4",
        "2": "pdf,txt,doc,wps,docx,xls,xlsx,ppt,pptx",
        "3": "pdf",
        "4": "pdf,zip,txt,doc,wps,docx,xls,xlsx,ppt,pptx",
        "5": "zip",
        "6": "tar",
        "7": "xls,xlsx"
      },
      accept: "mp4",
      // 文件上传进度
      uploadProgress: 0,
      // 上传成功的文件列表
      uploadList: [],
      // 外部链接上传的列表
      externalLinksList: [
        {
          uploadfile_id: "",
          path: "",
          name: "",
          type: ""
        }
      ],
      // 类型下拉数据
      drawerLibTypeList: [],
      // 上传类型数据
      uploadTypeList: [
        {
          id: "1",
          name: "本地上传"
        },
        {
          id: "2",
          name: "外部链接"
        }
      ],
      // 表单校验规则
      formRule: {
        category_id: [
          { required: true, message: '请选择题库', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        source_datas: [
          { validator: validateSource, trigger: 'blur' }
        ],
      },
      // 分类树的数据
      categoryTreeList: [],
      // 题库类型
      questionType: "0",
      // 题库类型数据
      questionTypeList: [],
      // 级联选择器配置
      optionProps: {
        value: "child_id",
        label: "name",
        children: "children",
        expandTrigger: 'hover'
      },
      // 实操专用
      shicaoObj: {
        uploadfile_id: "",
        path: "",
        name: "",
        type: "",
        link: ""
      },
      // 当前单元格行
      cellRowIndex: null,
      // 当前单元格列
      cellColumnIndex: null,
      WebOfficeInstance:null,
      webofficeShow:false
    }
  },
  computed: {
    btnTitle() {
      const { addEditForm } = this;
      return addEditForm.uploadType === "1" ? "上传文件" : "上传excel";
    }
  },
  methods: {
    // 获取资源类型数据
    getLibType() {
      this.api.dataset.libType().then(res => {
        const arr = [];
        Object.keys(res.types).forEach(key => {
          arr.push({
            id: key,
            name: res.types[key]
          });
        });
        this.drawerLibTypeList = arr;
      });
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      row.$index = rowIndex;
      column.index = columnIndex;
    },
    // 获取单元格内容
    // getCell(row, column) {
    //   this.cellRowIndex=row.$index;
    //   this.cellColumnIndex=column.$index;
    // },
    editName(scope) {
      this.cellRowIndex = scope.$index;
      this.cellColumnIndex = scope.column.index;
      this.$nextTick(() => {
        this.$refs["editName" + scope.$index].focus();
      });
    },
    nameInputBlur() {
      this.cellRowIndex = null;
      this.cellColumnIndex = null;
    },
    // 保存按钮
    shicaoSave() {
      const { shicaoObj, uploadList } = this;
      if (shicaoObj.uploadfile_id === "" && shicaoObj.link === "") {
        return this.$Message.error("请上传文件或者输入链接");
      }
      uploadList.push({
        ...shicaoObj
      });
      this.shicaoObj = {
        uploadfile_id: "",
        path: "",
        name: "",
        type: "",
        link: ""
      }
    },
    // 实操上传成功
    shicaoSuccess(res) {
      const { addEditForm, shicaoObj } = this;
      shicaoObj.uploadfile_id = res.data.info.id || "";
      shicaoObj.path = res.data.show_url || "";
      shicaoObj.name = res.data.info.name;
      shicaoObj.type = addEditForm.type;
    },
    // 添加外部链接
    addExternalLinks() {
      const { externalLinksList } = this;
      const flag = externalLinksList.some(item => {
        return item.path.trim() === "" || item.name.trim() === "";
      });
      if (flag) {
        return this.$Message.error("请输入外部链接以及名称");
      }
      externalLinksList.push({
        uploadfile_id: "",
        path: "",
        name: "",
        type: ""
      });
    },
    // 删除外部链接
    delExternal(index) {
      const { externalLinksList } = this;
      externalLinksList.splice(index, 1);
    },
    // 下载模板
    downloadTemplate() {
      util.downloadFile(
        this,
        CONSTANT.STATIC_URL + "/staticfile/source/import_template.xlsx"
      );
    },
    // 上传类型改变
    uploadTypeChange(value) {
      const { addEditForm, acceptObj } = this;
      if (value === "2") {
        this.accept = "xlsx";
      } else {
        this.accept = acceptObj[addEditForm.type];
      }
    },
    // 获取分类数据
    getCategoryData() {
      this.api.dataset.categoryForm().then((res) => {
        // 处理类型数据
        const arr = [];
        Object.keys(res.categoryTypes).forEach(key => {
          arr.push({
            id: key,
            name: res.categoryTypes[key]
          });
        });
        this.questionTypeList = arr;
      });
    },
    // 获取类型树数据
    getCategoryTreeData() {
      this.api.home.categoryTree({
        site_id: "-1",
        type: this.questionType
      }).then((res) => {
        this.categoryTreeList = res.tree || [];
      });
    },
    // 题库类型变化
    // questionTypeChange(value) {
    //   const { addEditForm } =this;
    //   this.questionType = value;
    //   addEditForm.categoryCasList = [];
    //   this.getCategoryTreeData();
    // },
    // 创建资源类型改变时
    libTypeChange(id) {
      this.uploadList = [];
      this.accept = this.acceptObj[id];
    },
    // 级联选择框改变
    // handleChange(value) {
    //   const { addEditForm } = this;
    //   if(value[2]){
    //     addEditForm.category_id = value[2];
    //   }
    // },
    // 上传文件之前钩子
    beforeUpload(file) {
      const { addEditForm }=this;
      this.uploadList.push({
        uploadfile_id: "",
        path: "",
        name: file.name,
        type: addEditForm.type,
        percentage:"0%"
      });
      this.uploadProgress = 0;
      this.uploadLoading = true;
    },
    // 上传中
    progress(file,percentage){
      const { uploadList }=this;
      const index=uploadList.findIndex(item=>item.name===file.name);
      uploadList[index].percentage=Math.floor((percentage*100))+"%";
      // console.log(percentage);
    },
    // 文件上传中钩子
    uploading(event) {
      if (event.lengthComputable) {
        this.uploadProgress = Math.round(event.loaded / event.total * 100);
      }
    },
    // 文件上传成功
    handleAvatarSuccess(res) {
      console.log("文件上传成功",res);
      const { addEditForm, uploadList } = this;
      if (addEditForm.uploadType === "2") {
        this.api.dataset.sourceImportUploadfile({
          // category_id: addEditForm.category_id,
          uploadfile_id: res.data ? res.data.info.id : res.info.id,
          type: addEditForm.type
        }).then((res) => {
          this.handleExcelData(res.source_list || []);
          this.$Message.success("excel上传成功");
        });
      } else {
        if (res?.data?.info) {
          addEditForm.uploadfile_id = res.data.info.id || "";
          const index=uploadList.findIndex(item=>item.name===res.data.params.name);
          if(index>=0){
            uploadList[index].uploadfile_id=res.data.info.id||"";
            uploadList[index].path=res.data.show_url||"";
            uploadList[index].name=res.data.info.name||"";
            uploadList[index].percentage="100%";
          }
          // uploadList.push({
          //   uploadfile_id: res.data.info.id || "",
          //   path: res.data.show_url || "",
          //   name: res.data.info.name,
          //   type: addEditForm.type,
          // });
        } else {
          addEditForm.uploadfile_id = res?.info?.id || "";
          const index=uploadList.findIndex(item=>item.name===res.params.name);
          if(index>=0){
            uploadList[index].uploadfile_id=res.info.id||"";
            uploadList[index].path=res.show_url||"";
            uploadList[index].name=res.info.name||"";
            uploadList[index].percentage="100%";
          }
          // uploadList.push({
          //   uploadfile_id: res.info.id || "",
          //   path: res.show_url || "",
          //   name: res.info.name,
          //   type: addEditForm.type,
          // });
        }
      }
      this.uploadLoading = false;
      this.uploadProgress = 0;
    },
    // 文件上传失败
    handleError(error) {
      this.uploadLoading = false;
      if (error) {
        this.$Message.error(error);
      }
    },
    // 整理excel数据
    handleExcelData(list) {
      const { externalLinksList } = this;
      list.forEach(item => {
        item.uploadfile_id = "";
      });
      if (!externalLinksList[0].name && !externalLinksList[0].path) {
        this.externalLinksList = [];
      }
      this.externalLinksList = [...this.externalLinksList, ...list];
    },
    // 抽屉确定按钮事件
    submit() {
      const { addEditForm, uploadList, externalLinksList } = this;
      this.$refs.addForm.validate(valid => {
        if (valid) {
          const params = {
            // category_id: addEditForm.category_id,
            type: addEditForm.type,
            source_datas: addEditForm.uploadType !== "2" ? uploadList : externalLinksList,
          }
          this.api.dataset.datasetAddLib(params).then((res) => {
            this.$Message.success("添加成功");
            this.$emit("submit", res.source_list || []);
          });
        }
      });
    },
    // 抽屉关闭事件
    drawerClose() {
      const { addEditForm } = this;
      for (let key in addEditForm) {
        addEditForm[key] = "";
      }
      addEditForm.categoryCasList = [];
      addEditForm.type = "1";
      addEditForm.uploadType = "1";
      this.$refs.addForm.resetFields();
      this.uploadList = [];
      this.externalLinksList = [];
      this.cellRowIndex = null;
      this.cellColumnIndex = null;
      this.externalLinksList=[{
        uploadfile_id: "",
        path: "",
        name: "",
        type: ""
      }];
      this.accept="mp4";
      this.$emit("cancel");
    },
    // 复制链接
    copyLink(row) {
      this.$copyText(row.path).then(() => {
        this.$Message.success("链接复制成功");
      });
    },
    // 预览
    preview(row) {
      console.log(row);
      // return;
      const { previewData } = this;
      // previewData.previewSrc = row.path;
      previewData.type = row.type;
      if (/http/.test(row.path)) {
        previewData.previewSrc = row.path;
        this.webofficeShow = false;
      } else {
        const webOfficeData = JSON.parse(row.path);
        this.webofficeShow = true;
        setTimeout(() => {
          this.WebOfficeInstance = WebOfficeSDK.init({
            ...webOfficeData,
            mount: this.$refs.webOfficeDOM
          });
        }, 0);
      }
      this.previewDrawer = true;
    },
    assignment(newVal){
      if(!newVal){
        newVal=this.drawerData;
      }
      console.log("新的数据",newVal);
      const { addEditForm } = this;
        // 题库类型赋值
      if (Number(newVal.type) > -1) {
        this.questionType = newVal.type;
      } else {
        this.questionType = "0";
      }
      // 资源类型赋值
      if (Number(newVal.libType) > 0) {
        addEditForm.type = newVal.libType;
        addEditForm.type = newVal.libType;
        this.accept = this.acceptObj[newVal.libType];
      } else {
        addEditForm.type = "1";
      }
      // 题库分类赋值
      if (newVal.level1Id && newVal.level2Id && newVal.level3Id) {
        addEditForm.categoryCasList = [newVal.level1Id, newVal.level2Id, newVal.level3Id];
        addEditForm.category_id=newVal.level3Id;
      }
      this.getCategoryTreeData();
    },
  },
  watch: {
    drawerShow: {
      handler: function (newValue) {
        if (this.defaultType && Number(this.defaultType) > 0) {
          this.accept = this.acceptObj[this.defaultType];
        }
        if(newValue){
          this.assignment(null);
        }
        this.drawer = newValue;
      },
      immediate: true
    },
    drawerData: {
      handler: function (newVal) {
        this.assignment(newVal);
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.addLibDrawer {
  .external-links {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .external-input {
      margin: 0 10px;
    }

    .external-add {
      margin-left: 10px;
    }
  }

  .uploadTips {
    position: absolute;
    left: 0;
    top: 0;
    width: 200px;
    height: 34px;
    overflow: hidden;

    .el-alert {
      height: 34px;
      margin-left: -2px;
    }
  }
}

.upload-loading {
  height: 178px;
  width: 178px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-edit {
  cursor: pointer;
  margin-left: 5px;
  color: #E6A23C;
}

.shicao-list {
  .shicao-wrap {
    width: 100%;
    display: flex;
  }

  .shicao-item {
    display: flex;
    align-items: center;
  }

  .shicao-input {
    margin-right: 20px;
  }
}
</style>