<template>
  <div>
    <div :id="idn" ref="filePicker" class="mySliceUpload"></div>
    <!-- <div v-for="item in uploadedFiles" :key="item.id">
      {{ item.name }}：{{ item.url }} 进度：{{ (parseFloat(item.percentage) * 100).toFixed(0)*1 }}% 上传id:{{item.uploadId}}
    </div> -->
  </div>
</template>

<script>
  import Cookies from "js-cookie";
  import WebUploader from "webuploader";
  import "webuploader/dist/webuploader.css";
  export  default {
    props:{
      // 上传组件的id
      idn:{
        type:String,
        required:true
      },
      accept:{
        type:String,
        default:"jpg,jpeg,png,gif,bmp,mp4,mp3,zip,rar,doc,docx,xls,pdf"
      },
      multiple:{
        type:Boolean,
        default:true
      },
      onErrorType:{
        type:Object,
      },
      // 上传按钮展示的文字
      btnTitle:{
        type:String,
        default:"上传文件"
      },
    },
    watch:{
      accept:{
        handler:function(){
          this.uploadedFiles=[];
          if(this.uploader){
            this.uploader.destroy();
            this.initWebUpload();
          }
        }
      }
    },
    data(){
      return{
        chunk_file_size:1024 * 1024 * 2,//2M
        currentSendFile:null,
        md5:'',
        Cookies:Cookies,
        token:Cookies.get(this.constant.tokenName),
        uploadUrl:this.constant.URL + '/uploadfile/upload',
        uploader:null,
        uploadedFiles:[],
        fileStatus:true,
        chunk_count:1,
        uploadfileExts:this.accept,
        chunk_file_list:[],
        percentage:0,
        chunks:1,
        md5Obj:{},//记录文件分片后所有分片的md5
        // fileNumLimit:10, //上传文件个数
        count:0,
        chunkedStatus:true,
      }
    },
    mounted(){
      if(this.uploader){
        this.uploader.destroy();
      }
      this.webuploaderRegister()
      this.initWebUpload();
    },
    methods:{
      getChunkCount(filesize){
        this.chunk_count = Math.ceil(filesize / this.chunk_file_size);
        return this.chunk_count;
      },

      webuploaderRegister(){
        console.warn("注册上传");
        let self = this;
        WebUploader.Uploader.register({
            name: `uploader${this.idn}`,
            'add-file': 'addFile', //向队列中添加文件
            'before-send-file': 'beforeSendFile', //整个文件上传前
            "before-send": "beforeSend", //每个文件分片上传前
          },{
            beforeSendFile: ( file ) => {
              this.$emit("beforeUpload",file);
              this.chunk_count = this.getChunkCount(file.size);
              var self = this;
              this.currentSendFile = file;
              var task = new WebUploader.Deferred();
              if(file.size > this.chunk_file_size){
                this.chunkedStatus = true;
              }else{
                this.chunkedStatus = false;
              }

              if (this.md5) {
                var data = {
                  file_md5: this.md5,
                  name: file.name,
                  op:1,
                  size:file.size,
                  chunk_count : this.chunk_count
                };
                console.warn("检测文件是否上传过");
                this.checkFileExit(data,task,file);
              } else {
                console.warn("整个文件md5加密");
                //获取文件MD5值
                (new WebUploader.Uploader()).md5File( file )// 及时显示进度
                    // .progress((percentage)=> {
                    //   if (self.currentSendFile && (file.id == self.currentSendFile.id)) {

                    //   } else {
                    //     task.reject("上传失败-md5-ing");
                    //     return task.promise();
                    //   }
                    // })
                    // 完成
                    .then((val)=> {
                      if (self.currentSendFile && (file.id == self.currentSendFile.id)) {
                        this.md5 = val;
                        var data = {
                          file_md5: this.md5,
                          name: file.name,
                          op:1,
                          size:file.size,
                          chunk_count : this.chunk_count
                        };
                        console.warn("检测文件是否上传过");
                        this.checkFileExit(data,task,file);
                      } else {
                        console.error("整个文件md5加密失败");
                        task.reject("上传失败-md5-node");
                      }
                    });
              }
              return task.promise();
            },
            beforeSend: (block) => {
              var task = new WebUploader.Deferred();
              console.warn("对切片进行md5加密");
              (new WebUploader.Uploader()).md5File( block.file,block.start, block.end ).then((val)=>{
                if(this.md5Obj[block.file.name]){
                  this.md5Obj[block.file.name].md5List[block.chunk] = val;
                }else{
                  this.md5Obj[block.file.name] = {
                    md5List:[],
                  }
                  this.md5Obj[block.file.name].md5List[block.chunk] = val;
                }
                let chunkFileList = self.chunk_file_list.map((item)=>{
                  return item.number;
                })
                if (~chunkFileList.indexOf(block.chunk.toString())) {
                  task.reject("上传失败-chunk");
                }else{
                  task.resolve();
                }
              })
              return task.promise();
            }
        });
      },

      initWebUpload () {
        console.warn("创建上传");
        this.uploader = WebUploader.create({
          auto: true, // 选完文件后，是否自动上传
          swf: "/swf/Uploader.swf",  // swf文件路径
          server: this.uploadUrl,  // 文件接收服务端
          pick: {
            id: "#"+this.idn,
            label: this.btnTitle
          },
          // paste: document.body,
          // dnd: '#uploader .drag-area',
          accept: {
            title: this.$t('project_file_format'),
            extensions: this.accept,
            mimeTypes: ''
          },  // 允许选择文件格式。
          threads: 2, // 并发进程数
          fileNumLimit: this.multiple?100:1, // 限制上传个数
          // formData: {
          //   access_token: this.token,
          // },  // 上传所需参数
          chunked: this.chunkedStatus,          //分片上传
          chunkSize: this.chunk_file_size,    //分片大小
          duplicate: true,  // 重复上传
          // multiple:false // 无效
        });
        //文件加入队列前
        this.uploader.on('beforeFileQueued', (file) => {
          console.warn("文件加入队列前");

          // if (!~this.uploadfileExts.indexOf(file.ext)) {
          //   this.$Message.destroy();
          //   this.$Message.warning(this.$t('admin_upload_file_format_incorrect'));
          //   return false
          // }
        });
        //文件加入队列后
        // this.uploader.on('fileQueued', (file) => {
        //   var $li = $(
        //       '<div id="' + file.id + '" class="file-item thumbnail">' +
        //       '<div class="upload-info">' +
        //       '<i class="ivu-icon ivu-icon-ios-document-outline" style="margin-right:3px"></i>' +
        //       '<span class="file-name">'+file.name+'</span>'+
        //       '<span class="state"></span>' +
        //       '<span class="reupload">'+self.$t('business_retry')+'</span>' +
        //       '</div>' +
        //       '<i class="ivu-icon ivu-icon-ios-close icon-close-uploading" style="font-size: 28px;"></i>'+
        //       '</div>'
        //   );
        //   $('#fileLilst').append($li);
        // });
        this.uploader.on('uploadBeforeSend', (object, data, headers) => { // 自定义上传参数
          console.warn("文件上传之前");
          if(this.chunkedStatus){
            data.op = '0';
            if(this.md5Obj[object.file.name]){
              data.file_md5 = this.md5Obj[object.file.name].md5List[object.chunk];
            }
            data.chunk_number = data.chunk || 0;
            data.chunk_count = data.chunks || 1;
            data.chunk_file_size = this.chunk_file_size;
            delete data.id;
            delete data.type;
            delete data.lastModifiedDate;
            delete data.chunk;
            delete data.chunks;
            headers['access-token'] = this.token;
          }else{
            // data.file = object.blob;
            data.name = data.name;
            data.size = data.size;
            delete data.id;
            delete data.type;
            delete data.lastModifiedDate;
            delete data.chunk;
            delete data.chunks;
            headers['access-token'] = this.token;
          }
          this.chunks = data.chunk_count || 1;
        });
        // this.uploader.on('uploadAccept', (file, res) => {
          // if(!this.chunkedStatus){
          //   this.uploadedFiles.forEach((item)=>{
          //     if(item.id == file.file.id){
          //       this.$set(item,'url',res.data.show_url);
          //       this.$set(item,'uploadId',res.data.info.id);
          //     }
          //   })
          // }
          // this.$emit("success",res);
        // });
        this.uploader.on('uploadProgress', (file, percentage) => {
          // console.log("上传进度",percentage);
          this.$emit("progress",file,percentage);
          this.uploadedFiles.forEach((item)=>{
            if(item.id == file.id){
              this.$set(item,'percentage',percentage);
            }
          })
        });

        this.uploader.on('uploadSuccess', (file, res) => {
          console.warn("上传成功",res);
          if (res && this.chunkedStatus) {
            let data = {
              name:file.name,
              file_md5:this.md5,
              chunk_count:this.chunks || 1,
              op:4,
              size:file.size
            };
            this.api.index.uploadfileUpload(data).then((res)=>{
              console.log("合并",res);
              this.$emit("success",res);
              this.uploadedFiles.forEach((item)=>{
                if(item.id == file.id){
                  this.$set(item,'url',res.show_url);
                  this.$set(item,'uploadId',res.info.id);
                }
              })
            });
          }else if(res){
            this.$emit("success",res);
          }
        });
        this.uploader.on('uploadError', (file, reason) => {
          this.$Message.error(reason ? reason : '上传失败，请重试！');
        });

        // 当validate不通过时，会以派送错误事件的形式通知调用者。通过upload.on('error', handler)可以捕获到此类错误，目前有以下错误会在特定的情况下派送错来。

        //Q_EXCEED_NUM_LIMIT 在设置了fileNumLimit且尝试给uploader添加的文件数量超出这个值时派送。
        //Q_EXCEED_SIZE_LIMIT 在设置了Q_EXCEED_SIZE_LIMIT且尝试给uploader添加的文件总大小超出这个值时派送。
        //Q_TYPE_DENIED 当文件类型不满足时触发。。
        this.uploader.on('error', (type) => {
          console.error("错误");
          if (type === 'Q_EXCEED_NUM_LIMIT') {
            this.$Message.destroy();
            this.$emit("error",'超过上传个数限制');
          } else if (type == 'Q_TYPE_DENIED') {
            this.$Message.destroy();
            this.$emit("error",'不能上传此类型');
          }
          this.uploader.reset();
        });

        this.uploader.on('uploadComplete', (file, response) => {
          console.warn("上传完成");
          this.count++;
          this.fileStatus = true;
          if(this.count >= this.fileNumLimit){  //全部传完后出发重置
            this.uploader.reset();
            this.count = 0;
          }
          this.md5 = '';
          this.md5Obj = {};
        });
      },

      checkFileExit(data,task,file){
        this.api.index.uploadfileUpload(data).then((res)=>{
          this.chunk_file_list = res.chunk_file_list || [];
          if(res.show_url) {
            this.$emit("success",res);
              this.uploadedFiles.forEach((item)=>{
                if(item.id == file.id){
                  this.$set(item,'url',res.show_url);
                  this.$set(item,'uploadId',res.info.id);
                }
              })
            // task.reject('文件已存在'); // 文件存在，则跳过上传
            console.warn("文件已经上传过");
            this.uploader.skipFile( file );
            task.resolve();
          }else if(this.chunk_file_list.length == this.chunk_count){
            let data = {
              name:file.name,
              file_md5:this.md5,
              chunk_count:this.chunk_count || 1,
              op:4,
              size:file.size
            };
            this.api.index.uploadfileUpload(data).then((res)=>{
              this.$emit("success",res);
              this.uploadedFiles.forEach((item)=>{
                if(item.id == file.id){
                  this.$set(item,'url',res.show_url);
                  this.$set(item,'uploadId',res.info.id);
                }
              });
            });
            this.uploader.skipFile( file );
            task.resolve();
          } else {
            this.uploadedFiles.push({
              ...file
            })
            this.fileStatus = true;
            task.resolve();
          }
        }).catch(()=>{
          this.fileStatus = false;
          task.reject("上传失败-api");
        });
      }
    }
  }
</script>

<style lnag="scss">
  .mySliceUpload{
    height: 32px;
    .webuploader-pick{
      height: 32px !important;
      line-height: 12px;
      overflow: hidden;
    }
    div{
      height: 32px !important;
    }
  }
</style>
